@layer components {
  .alert {
    @apply flex gap-4 items-start justify-between my-4 py-2 px-4 bg-status-info/5 text-status-info rounded;

    &.alert-success {
      @apply bg-status-success/5 text-status-success;
    }

    &.alert-warning {
      @apply bg-status-warning/5 text-status-warning;
    }

    &.alert-critical {
      @apply bg-status-critical/5 text-status-critical;
    }

    .prose {
      @apply flex-1 text-sm font-medium;

      p,
      li {
        @apply my-1;
      }
    }

    .alert-dismiss {
      @apply p-1.5 -mr-1.5;
      @apply outline outline-1 outline-offset-4 outline-transparent;
      font-size: 0;

      &:focus-visible {
        @apply outline outline-2 outline-offset-2 outline-current;
      }
    }
  }
}
